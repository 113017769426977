@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .slider {
      appearance: none;
      outline: none;
      transition: opacity 0.3s;
      width: 100%;
      margin: 2rem 0;
      cursor: pointer;
  
      /* Track */
      &::-webkit-slider-runnable-track {
        height: 0.25rem;
        background: #888888;
        border-radius: 0.5rem;
      }
  
      &::-moz-range-track {
        height: 0.25rem;
        background: #888888;
        border-radius: 0.5rem;
      }
  
      /* Thumb */
      &::-webkit-slider-thumb {
        appearance: none;
        height: 1rem;
        width: 1rem;
        border-radius: 0.5rem;
        background: #ffffff;
        border: 1px solid #000000;
        margin-top: -0.375rem; 
        transition: background-color 0.3s;
      }
  
      &::-moz-range-thumb {
        appearance: none;
        height: 1rem;
        width: 1rem;
        border-radius: 0.5rem;
        background: #ffffff;
        border: 1px solid #000000;
        transition: background-color 0.3s;
      }
    }
  
    .slider:hover {
      opacity: 0.8;
    }
  }