@font-face {
    font-family: "Inter Regular"; 
    src: url("assets/fonts/Inter-Regular.woff2") format("woff2");
    font-display: swap;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  }
  
  .hide-scrollbar {
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }
  